import api from "../utils/api";

export const getModulos = async () => {
  try {
    const fetchData = await api.get("/api/modulos/listar");
    return fetchData.data;
  } catch (error) {
    console.error(error);
  }
};
