import { Accordion, Group, List, Stack, Text } from "@mantine/core";
import { YouTubeEmbed } from "../youtubeEmbed";

export const AccordionRequerimento = () => {
  return (
    <Accordion.Item value={"requerimento"}>
      <Accordion.Control>
        <Text fw={"bold"} c={"#333"}>
          Etapa 4: Requerimento
        </Text>
      </Accordion.Control>
      <Accordion.Panel>
        <Group justify="space-between" align="flex-start">
          <Stack gap={"1rem"} w={"55%"}>
            <Text>
              A etapa de{" "}
              <Text span fw={700}>
                Requerimento
              </Text>{" "}
              é crucial no cadastro do protocolo, pois define o tipo de licença
              ou declaração necessária e as atividades associadas ao
              empreendimento.
            </Text>
            <List type="ordered" spacing={"1.5rem"}>
              <List.Item>
                <Text fw={700}>Escolha o Tipo de Requerimento</Text>
                <List>
                  <List.Item>
                    Selecione o tipo de requerimento adequado ao protocolo.
                    Opções disponíveis incluem:
                    <List spacing={"0.4rem"}>
                      <List.Item>
                        <Text fw={700}>Declaração de Inexigibilidade</Text>
                      </List.Item>
                      <List.Item>
                        <Text fw={700}>Licença Ambiental</Text>
                      </List.Item>
                      <List.Item>
                        <Text fw={700}>Atualização de Licença</Text>
                      </List.Item>
                      <List.Item>
                        <Text fw={700}>Solicitação de Outorga</Text>
                      </List.Item>
                      <List.Item>
                        <Text fw={700}>Outros</Text>
                      </List.Item>
                    </List>
                  </List.Item>
                  <Text>
                    Após selecionar o tipo de requerimento, o sistema validará
                    automaticamente se ele pode ser solicitado na localização do
                    empreendimento.
                  </Text>
                </List>
              </List.Item>
              <List.Item>
                <Text fw={700}>Cadastro de Licença Ambiental</Text>
                Caso você tenha selecionado Licença Ambiental, siga os passos
                abaixo para completar o cadastro:
                <List type="ordered">
                  <List.Item>
                    <Text fw={700}>Defina o Tipo de Licença</Text>
                    <List>
                      <List.Item>
                        Escolha o tipo de licença necessária, como:
                        <List spacing={"0.4rem"}>
                          <List.Item>
                            <Text fw={700}>Licença Prévia</Text>
                          </List.Item>
                          <List.Item>
                            <Text fw={700}>Licença de Instalação</Text>
                          </List.Item>
                          <List.Item>
                            <Text fw={700}>Licença de Operação</Text>
                          </List.Item>
                          <List.Item>
                            <Text fw={700}>Renovação de Licença</Text>
                          </List.Item>
                        </List>
                      </List.Item>
                    </List>
                  </List.Item>
                  <List.Item>
                    <Text fw={700}>
                      Selecione a Tipologia do Empreendimento
                    </Text>
                    <List type="ordered" spacing={"0.4rem"}>
                      <List.Item>
                        Escolha a{" "}
                        <Text span fw={700}>
                          tipologia
                        </Text>{" "}
                        correspondente ao empreendimento.
                      </List.Item>
                      <List.Item>
                        Uma página de adição de atividade será exibida, onde
                        você deverá:
                        <List spacing={"0.4rem"}>
                          <List.Item>
                            Selecionar as atividades relacionadas ao
                            empreendimento.
                          </List.Item>
                          <List.Item>
                            Escolher os{" "}
                            <Text span fw={700}>
                              CNAEs
                            </Text>{" "}
                            (Classificação Nacional de Atividades Econômicas)
                            que descrevem essas atividades.
                            <List>
                              <List.Item>
                                É possível selecionar mais de um CNAE, caso
                                aplicável.
                              </List.Item>
                            </List>
                          </List.Item>
                          <List.Item>
                            Indicar os{" "}
                            <Text span fw={700}>
                              parâmetros
                            </Text>{" "}
                            exigidos: área útil e número de empregados.
                          </List.Item>
                        </List>
                      </List.Item>
                    </List>
                  </List.Item>
                  <List.Item>
                    <Text fw={700}>Adicione as Atividades</Text>
                    <List spacing={"0.4rem"}>
                      <List.Item>
                        Clique em{" "}
                        <Text span fw={700}>
                          "Adicionar Atividade"
                        </Text>{" "}
                        para registrar as informações.
                      </List.Item>
                      <List.Item>
                        Nota: Atividades adicionadas podem ser editadas ou
                        removidas nesta etapa, caso necessário.
                      </List.Item>
                    </List>
                  </List.Item>
                  <List.Item>
                    <Text fw={700}>Detalhe as Atividades</Text>
                    <List spacing={"0.4rem"} type="ordered">
                      <List.Item>
                        Preencha uma descrição detalhada de cada atividade
                        cadastrada, explicando o que será executado.
                      </List.Item>
                      <List.Item>
                        Informe a{" "}
                        <Text span fw={700}>
                          vigência requerida
                        </Text>
                        , conforme o limite estabelecido pelo sistema.
                        <List>
                          <List.Item>
                            O sistema não permite inserir um período superior ou
                            inferior ao limite estabelecido.
                          </List.Item>
                        </List>
                      </List.Item>
                    </List>
                  </List.Item>
                </List>
              </List.Item>
              <List.Item>
                <Text fw={700}>Avance para a Próxima Etapa</Text>
                <List>
                  <List.Item>
                    Após finalizar o preenchimento, clique em{" "}
                    <Text span fw={700}>
                      "Avançar"
                    </Text>{" "}
                    para prosseguir para a próxima etapa
                  </List.Item>
                </List>
              </List.Item>
            </List>
          </Stack>
          <YouTubeEmbed
            title="Requerimento"
            link="https://www.youtube.com/watch?v=c1b2IIrWps8&list=PLN8ZE762l-EC5wX0y3hB0IZ0NYNGTV3gN&index=7&ab_channel=ImprensaIpaam"
          />
        </Group>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
