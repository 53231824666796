import React, { useEffect, useState } from "react";
import { getDuvidas } from "../../services/duvidas";
import { Stack, Text } from "@mantine/core";
import { Accordion } from "@mantine/core";

const Duvidas = () => {
  const [duvidas, setDuvidas] = useState([]);

  const setDadosDuvidas = async () => {
    const data = await getDuvidas();
    setDuvidas(data);
  };

  useEffect(() => {
    setDadosDuvidas();
  }, []);

  return (
    <Stack gap={"1rem"}>
      <Text fz={"xl"} c={"#295661"} fw={"bold"}>
        Perguntas frequentes
      </Text>
      <Accordion
        variant="contained"
        styles={{
          item: {
            backgroundColor: "#fff",
            "&[data-active]": {
              backgroundColor: "#fff",
            },
          },
          chevron: {
            "&[data-rotate]": {
              transform: "rotate(-90deg)",
            },
          },
        }}
        multiple
      >
        {duvidas.map((duvida, index) => (
          <Accordion.Item key={index} value={duvida?.titulo}>
            <Accordion.Control>
              <Text fw={"bold"} c={"#333"}>
                {duvida?.titulo}
              </Text>
            </Accordion.Control>
            <Accordion.Panel>
              <Text
                c={"#666"}
                dangerouslySetInnerHTML={{
                  __html: duvida?.descricao,
                }}
              />
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </Stack>
  );
};
export default Duvidas;
