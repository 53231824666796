import React, { useState } from "react";
import {
  HeaderStyle,
  Container,
  MenuContainer,
  MenuItem,
  Link,
  BotaoFechar,
  PesquisaContainer,
} from "./style";
import Input from "../input";
import CloseIcon from "../../assets/icons/CloseIcon";
import {
  ACESSIBILIDADE,
  CONTRASTE,
  MAPA_SITE,
  URL_IPAAM,
  URL_MAPA_SITE,
} from "../../utils/Constants";
import { Divider, Group, Image } from "@mantine/core";

const HeaderPrincipal = () => {
  const [pesquisa, setPesquisa] = useState("");

  const AlterarPesquisa = (event) => {
    setPesquisa(event.target.value);
  };

  const LimparPesquisa = () => {
    setPesquisa("");
  };

  const Pesquisar = () => {
    const url = `${URL_IPAAM}?s=${pesquisa}`;
    window.open(url, "_blank", "noreferrer");
  };

  const basePath = `${process.env.PUBLIC_URL}/images/`;

  return (
    <HeaderStyle>
      <Group pl={30} gap={"2rem"}>
        <Image
          style={{ cursor: "pointer" }}
          src={`${basePath}logoAm.png`}
          alt="Logo amazonas"
          h={60}
          onClick={() => {
            window.open("https://www.amazonas.am.gov.br/");
          }}
        />
        <Divider orientation="vertical" />
        <Image
          style={{ cursor: "pointer" }}
          src={`${basePath}logoIpaam.png`}
          alt="Logo amazonas"
          h={60}
          onClick={() => {
            window.open("https://www.ipaam.am.gov.br/");
          }}
        />
      </Group>
      <Container>
        <section>
          <>
            <MenuContainer>
              <MenuItem>
                <Link href="#">{ACESSIBILIDADE}</Link>
              </MenuItem>
              <MenuItem>
                <Link href="#">{CONTRASTE}</Link>
              </MenuItem>
              <MenuItem>
                <Link href={URL_MAPA_SITE} target="_blank">
                  {MAPA_SITE}
                </Link>
              </MenuItem>
            </MenuContainer>
            <PesquisaContainer>
              <Input
                handleChange={AlterarPesquisa}
                value={pesquisa}
                handleKeyPress={Pesquisar}
              ></Input>
              <BotaoFechar onClick={LimparPesquisa}>
                <CloseIcon />
              </BotaoFechar>
            </PesquisaContainer>
          </>
        </section>
      </Container>
    </HeaderStyle>
  );
};

export default HeaderPrincipal;
