import "@mantine/core/styles.css";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { MRT_Localization_PT_BR } from "mantine-react-table/locales/pt-BR";
import { Box, Text } from "@mantine/core";

const Tabela = ({ data, columns, rowCount, title, setPagination, state }) => {
  const table = useMantineReactTable({
    columns,
    data,
    enableTopToolbar: false,
    enableSorting: false,
    enableRowActions: false,
    enableColumnActions: false,
    manualPagination: true,
    enablePagination: true,
    rowCount: rowCount,
    localization: {
      ...MRT_Localization_PT_BR,
      rowsPerPage: "Atividades por página",
    },
    onPaginationChange: setPagination,
    state,
  });

  return (
    <Box>
      <Text fw={"bold"} c={"#333"} mb={"0.5rem"}>
        {title}
      </Text>
      <MantineReactTable table={table} />
    </Box>
  );
};

export default Tabela;
