import styled from "styled-components";

export const Pagina = styled.div`
  min-height: 740px;
  margin-top: -30px;
`;

export const HeaderArrow = styled.span`
  display: flex;
  align-items: center;
  /* font-size: 28px; */
  font-weight: bold;
  align-items: center;
  margin-top: 5px;
  color: #295661;
  margin-right: 5px;
`;

export const BotaoVoltar = styled.div`
  border: none;
  display: flex;
  color: #295661;
  cursor: pointer;
  margin-right: 5px;
`;

export const Titulo = styled.span`
  font-family: "SF Pro Display";
  font-weight: ${(props) => (props.negrito ? "bold" : 300)};
  font-size: 24px;
  line-height: 29px;
  padding: 32px 5px 16px 0px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.secundaria};
`;

export const HeaderAcessoInterno = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  height: 24px;
  color: #295661;
  margin-bottom: 20px;
`;

export const Descricao = styled.span`
  font-family: "SF Pro Display";
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 32px;
  display: flex;
  color: ${(props) => props.theme.colors.secundaria};
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  position: relative;
  width: 315px;
  height: 450px;
  background: ${(props) => props.theme.colors.Branco};
  box-shadow: 10px 10px 21px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin: 20px;
`;

export const BotaoCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 8px 16px;
  width: 17rem;
  bottom: 0%;
  height: 2rem;
  background: ${(props) => props.theme.colors.Verde};
  box-shadow: 14px 15px 21px rgba(0, 0, 0, 0.05);
  border-radius: 230px;
  margin-bottom: 20px;
  &:hover {
    cursor: pointer;
  }
`;
