import { Button, Card, Group, Stack, Text } from "@mantine/core";

export const CardInformacoesRequerimento = ({
  requerimento,
  handleExportPDF,
}) => {
  return (
    <Card
      shadow="xs"
      padding={"lg"}
      radius="sm"
      style={{
        backgroundColor: "#DDE9ED",
      }}
    >
      <Stack gap={"1.5rem"}>
        <Group justify="space-between">
          <Stack gap={"0.5rem"}>
            <Text size="1.5rem" c={"#295661"} fw={"bold"}>
              Requisitos para o requerimento {requerimento?.fase}
            </Text>
            <Text size={"1.15rem"} c={"#295661"}>
              {requerimento?.nomenclatura}
            </Text>
          </Stack>
          <Button variant="subtle" onClick={handleExportPDF}>
            Exportar PDF
          </Button>
        </Group>
        <Group gap={"5rem"} align="flex-start">
          <Stack gap={"0.5rem"}>
            <Text c={"#295661"}>
              Fase:{" "}
              <Text span fw={"bold"}>
                {requerimento?.fase}
              </Text>
            </Text>
            <Text c={"#295661"}>
              Gerência competente:{" "}
              <Text span fw={"bold"}>
                {requerimento?.siglaGerencia}
              </Text>
            </Text>
          </Stack>
          <Stack gap={"0.5rem"}>
            <Text c={"#295661"}>
              Nomenclatura:{" "}
              <Text span fw={"bold"}>
                {requerimento?.nomenclatura}
              </Text>
            </Text>
          </Stack>
        </Group>
      </Stack>
    </Card>
  );
};
