import { Accordion, Group, List, Stack, Text } from "@mantine/core";
import { YouTubeEmbed } from "../youtubeEmbed";

export const AccordionDocumentacao = () => {
  return (
    <Accordion.Item value={"documentacao"}>
      <Accordion.Control>
        <Text fw={"bold"} c={"#333"}>
          Etapa 8: Documentação
        </Text>
      </Accordion.Control>
      <Accordion.Panel>
        <Group justify="space-between" align="flex-start">
          <Stack gap={"1rem"} w={"55%"}>
            <List type="ordered">
              <List.Item>
                <Text fw={700}>Consulte a Legenda</Text>
                <List>
                  <List.Item>
                    No canto superior direito da tela, uma{" "}
                    <Text span fw={700}>
                      legenda
                    </Text>{" "}
                    exibe o status de cada documento:
                    <List>
                      <List.Item>
                        <Text span fw={700}>
                          Obrigatório:
                        </Text>{" "}
                        Documentos que devem ser enviados para continuar o
                        protocolo.
                      </List.Item>
                      <List.Item>
                        <Text span fw={700}>
                          Enviado:
                        </Text>{" "}
                        Documentos já carregados no sistema.
                      </List.Item>
                      <List.Item>
                        <Text span fw={700}>
                          Opcional:
                        </Text>{" "}
                        Documentos que podem ser anexados, mas não são
                        obrigatórios.
                      </List.Item>
                    </List>
                  </List.Item>
                </List>
              </List.Item>

              <List.Item mt={"1.5rem"}>
                <Text fw={700}>Faça o Upload de Documentos</Text>
                <List>
                  <List.Item>
                    Para enviar um documento, localize a coluna de{" "}
                    <Text span fw={700}>
                      ações
                    </Text>{" "}
                    e clique no ícone de
                    <Text span fw={700}>
                      {" "}
                      upload
                    </Text>
                    .
                  </List.Item>
                  <List.Item>
                    Selecione o arquivo desejado no seu dispositivo e envie para
                    o sistema.
                  </List.Item>
                  <List.Item>
                    <Text span fw={700}>
                      Variedade de Documentos Exigidos
                    </Text>
                    <List>
                      <List.Item>
                        Os documentos solicitados variam conforme o tipo de
                        empreendimento e as atividades cadastradas.
                      </List.Item>
                      <List.Item>
                        Certifique-se de que os arquivos enviados estão
                        completos, legíveis e no formato aceito pelo sistema.
                      </List.Item>
                    </List>
                  </List.Item>
                </List>
              </List.Item>

              <List.Item mt={"1.5rem"}>
                <Text fw={700}>Confirme a Veracidade das Informações</Text>
                <List>
                  <List.Item>
                    No final da página, marque a caixa de seleção para declarar
                    que as informações fornecidas são verdadeiras e completas.
                  </List.Item>
                </List>
              </List.Item>

              <List.Item mt={"1.5rem"}>
                <Text fw={700}>Avance para a Próxima Etapa</Text>
                <List>
                  <List.Item>
                    Após anexar os documentos necessários e marcar a declaração,
                    clique em{" "}
                    <Text span fw={700}>
                      "Avançar"
                    </Text>{" "}
                    para prosseguir.
                  </List.Item>
                </List>
              </List.Item>
            </List>
          </Stack>
          <YouTubeEmbed
            title="Documentacao"
            link="https://www.youtube.com/watch?v=GAl5U1d33jw&list=PLN8ZE762l-EC5wX0y3hB0IZ0NYNGTV3gN&index=11"
          />
        </Group>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
