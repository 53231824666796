import { faBookOpen, faScaleBalanced } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Group, Stack, Text } from "@mantine/core";

export const AcessoInformacao = () => {
  return (
    <Stack gap={"1rem"}>
      <Text fz={"xl"}>
        Acesso a{" "}
        <Text span fw={"bolder"} fz={"xl"}>
          Informação
        </Text>
      </Text>
      <Group justify="space-between" align="stretch">
        <Card
          shadow="sm"
          withBorder
          w={"48%"}
          mih={230}
          style={{ display: "flex" }}
        >
          <Stack h={"100%"} style={{ flex: 1 }}>
            <Group>
              <FontAwesomeIcon icon={faScaleBalanced} color="green" size="2x" />{" "}
              <Text fw={"bolder"} fz={"1.15rem"} c={"#333"}>
                Lei Estadual n.º 3.785/12
              </Text>
            </Group>
            <Text c={"#666"}>
              O licenciamento ambiental é um instrumento da Política Nacional de
              Meio Ambiente que tem como objetivo compatibilizar o
              desenvolvimento econômico-social com um meio ambiente
              equilibrado.O IPAAM segue o descrito na Lei Estadual n.º 3.785, de
              24 de julho de 2012, para o licenciamento ambiental, autorizações,
              cadastros e/ou demais licenças/autorizações pertinentes.
            </Text>
            <Button
              variant="subtle"
              color="green"
              ml={"auto"}
              mt={"auto"}
              fw={"lighter"}
              onClick={() => {
                window.open(
                  "http://online.sefaz.am.gov.br/silt/Normas/Legisla%E7%E3o%20Estadual/Lei%20Estadual/Ano%202012/Arquivo/LE%203785_12.htm"
                );
              }}
            >
              Saiba mais
            </Button>
          </Stack>
        </Card>
        <Card
          shadow="sm"
          withBorder
          w={"48%"}
          mih={230}
          style={{ display: "flex" }}
        >
          <Stack h={"100%"} style={{ flex: 1 }}>
            <Group>
              <FontAwesomeIcon icon={faBookOpen} color="green" size="2x" />{" "}
              <Text fw={"bolder"} fz={"1.15rem"} c={"#333"}>
                Outras legislações pertinentes
              </Text>
            </Group>
            <Text c={"#666"}>
              Através deste acesso serão disponibilizadas legislações ambientais
              estaduais, portarias internas referentes aos sistemas entre outras
              informações.
            </Text>
            <Button
              variant="subtle"
              color="green"
              ml={"auto"}
              mt={"auto"}
              fw={"lighter"}
              onClick={() => {
                window.open("https://www.ipaam.am.gov.br/legislacao-sislam/");
              }}
            >
              Saiba mais
            </Button>
          </Stack>
        </Card>
      </Group>
    </Stack>
  );
};
