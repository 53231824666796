import { Accordion, Group, List, Stack, Text } from "@mantine/core";
import { YouTubeEmbed } from "../youtubeEmbed";

export const AccordionRepresentantes = () => {
  return (
    <Accordion.Item value={"representantes"}>
      <Accordion.Control>
        <Text fw={"bold"} c={"#333"}>
          Etapa 3: Proprietários e Representantes
        </Text>
      </Accordion.Control>
      <Accordion.Panel>
        <Group justify="space-between" align="flex-start">
          <Stack gap={"1rem"} w={"55%"}>
            <Stack gap={"0.5rem"}>
              {" "}
              <Text>
                Nesta etapa, você pode vincular{" "}
                <Text span fw={700}>
                  proprietários, representantes e responsáveis
                </Text>{" "}
                ao empreendimento. Aqui estão alguns pontos importantes:
              </Text>
              <List withPadding>
                <List.Item>
                  <Text span fw={700}>
                    Esta etapa não é obrigatória.
                  </Text>
                </List.Item>
                <List.Item>
                  Apenas pessoas cadastradas na base de dados do{" "}
                  <Text span fw={700}>
                    Entrada Única
                  </Text>{" "}
                  podem ser vinculadas.
                </List.Item>
              </List>
            </Stack>
            <List type="ordered" spacing={"1.5rem"}>
              <List.Item>
                <Text fw={700}>Vinculando Proprietários</Text>
                <List type="ordered" spacing={"0.3rem"}>
                  <List.Item>
                    No campo indicado, insira o{" "}
                    <Text span fw={700}>
                      CPF/CNPJ
                    </Text>{" "}
                    do proprietário que deseja vincular.
                  </List.Item>
                  <List.Item>
                    Clique no botão{" "}
                    <Text span fw={700}>
                      "Vincular Proprietário"
                    </Text>
                  </List.Item>
                  <List.Item>
                    Uma tela de confirmação será exibida. Verifique os dados e
                    clique novamente em{" "}
                    <Text span fw={700}>
                      "Vincular Proprietário"
                    </Text>
                    .
                  </List.Item>
                  <List.Item>
                    Repita o processo até que todos os proprietários tenham sido
                    vinculados ao protocolo.
                  </List.Item>
                </List>
              </List.Item>
              <List.Item>
                <Text fw={700}>Vinculando Representantes</Text>
                <List type="ordered" spacing={"0.3rem"}>
                  <List.Item>
                    No campo indicado, insira o{" "}
                    <Text span fw={700}>
                      CPF/CNPJ
                    </Text>{" "}
                    do representante.
                  </List.Item>
                  <List.Item>
                    Clique no botão{" "}
                    <Text span fw={700}>
                      "Vincular Representante"
                    </Text>
                  </List.Item>
                  <List.Item>
                    O sistema exigirá o upload de um{" "}
                    <Text span fw={700}>
                      documento comprobatório
                    </Text>
                    , como uma procuração ou declaração de representação.
                  </List.Item>
                  <List.Item>
                    Após anexar o documento, clique em{" "}
                    <Text span fw={700}>
                      "Vincular Representante"
                    </Text>{" "}
                    para concluir.
                  </List.Item>
                  <List.Item>
                    Repita o processo para adicionar todos os representantes
                    necessários.
                  </List.Item>
                </List>
              </List.Item>
              <List.Item>
                <Text fw={700}>Vinculando Responsáveis</Text>
                <List type="ordered" spacing={"0.3rem"}>
                  <List.Item>
                    No campo indicado, insira o{" "}
                    <Text span fw={700}>
                      CPF/CNPJ
                    </Text>{" "}
                    do responsável.
                  </List.Item>
                  <List.Item>
                    Faça o upload de um{" "}
                    <Text span fw={700}>
                      documento comprobatório
                    </Text>{" "}
                    que valide sua responsabilidade no empreendimento.
                  </List.Item>
                  <List.Item>
                    Selecione o tipo de responsável:
                    <List>
                      <List.Item>
                        <Text span fw={700}>
                          Técnico:{" "}
                        </Text>
                        Para responsáveis técnicos relacionados às operações.
                      </List.Item>
                      <List.Item>
                        <Text span fw={700}>
                          Legal:{" "}
                        </Text>
                        Para responsáveis legais vinculados ao empreendimento.
                      </List.Item>
                    </List>
                  </List.Item>
                  <List.Item>
                    Clique em{" "}
                    <Text span fw={700}>
                      "Vincular Responsável"
                    </Text>{" "}
                    para confirmar a ação.
                  </List.Item>
                  <List.Item>
                    Repita o processo para adicionar todos os responsáveis
                    necessários.
                  </List.Item>
                </List>
              </List.Item>
            </List>
            <Stack gap={"0.5rem"}>
              <Text fw={700}>Dicas e Observações</Text>
              <List withPadding>
                <List.Item>
                  Certifique-se de que os documentos anexados estejam legíveis e
                  em conformidade com os requisitos do sistema.
                </List.Item>
                <List.Item>
                  Você pode consultar os dados dos proprietários, representantes
                  ou responsáveis diretamente na listagem da tela, após a
                  vinculação.
                </List.Item>
                <List.Item>
                  Caso precise alterar ou excluir vínculos, será necessário
                  acessar o Entrada Única, pois as alterações não podem ser
                  feitas diretamente no SISLAM.
                </List.Item>
              </List>
            </Stack>
          </Stack>
          <YouTubeEmbed
            title="Proprietários Representantes"
            link="https://www.youtube.com/watch?v=8b6mXyky6h4&list=PLN8ZE762l-EC5wX0y3hB0IZ0NYNGTV3gN&index=6&ab_channel=ImprensaIpaam"
          />
        </Group>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
