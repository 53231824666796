import { Paper } from "@mantine/core";

export const YouTubeEmbed = ({ title, link }) => {
  const videoId = link.includes("watch")
    ? new URL(link).searchParams.get("v")
    : link.split("/").pop();

  const embedLink = `https://www.youtube.com/embed/${videoId}`;

  return (
    <Paper
      shadow="md"
      radius="md"
      withBorder
      style={{
        width: "35%",
        margin: "0 auto",
        padding: "10px",
        backgroundColor: "#f8f9fa",
      }}
    >
      <div
        style={{
          position: "relative",
          paddingBottom: "56.25%",
          height: 0,
          overflow: "hidden",
        }}
      >
        <iframe
          src={embedLink}
          title={title}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            border: 0,
          }}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </Paper>
  );
};
