import { BrowserRouter, Routes, Route } from "react-router-dom";
import BaseLayout from "./pages/BaseLayout";
import Home from "./pages/Home";
import Contato from "./pages/Contato";
import Duvidas from "./pages/Duvidas";
import Servicos from "./pages/Servicos";
import Theme from "./Theme";
import AcessoInterno from "./pages/AcessoInterno";
import { LoadingOverlay, MantineProvider } from "@mantine/core";
import { EncontreAtividade } from "./pages/EncontreAtividade";
import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";
import "mantine-react-table/styles.css";
import { InformacoesAtividade } from "./pages/InformacoesAtividades";
import { InformacoesRequerimentos } from "./pages/InformacoesRequerimentos";
import { useLoadingStore } from "./stores/LoadingStore";
import { GuiasRapidos } from "./pages/GuiasRapidos";

export default function App() {
  const isLoading = useLoadingStore((state) => state.isLoading);

  const theme = {
    colors: {
      brand: [
        "#295661",
        "#295661",
        "#295661",
        "#295661",
        "#295661",
        "#295661",
        "#295661",
        "#295661",
        "#295661",
        "#295661",
      ],
    },
    colorScheme: "light",
    fontFamily: "'Ubuntu', sans-serif",
    fontFamilyMonospace: "Monaco, Courier, monospace",
    headings: { fontFamily: "'Ubuntu', sans-serif" },
    primaryColor: "brand",
    components: {
      Select: {
        defaultProps: {
          size: "md",
        },
      },
      DateTimePicker: {
        defaultProps: {
          size: "md",
        },
      },
      Textarea: {
        defaultProps: {
          size: "md",
        },
      },
      SegmentedControl: {
        defaultProps: {
          size: "md",
        },
      },
      MultiSelect: {
        defaultProps: {
          size: "md",
        },
      },
      NumberInput: {
        defaultProps: {
          size: "md",
        },
      },
      Text: {
        defaultProps: {
          size: "md",
        },
      },
      Checkbox: {
        defaultProps: {
          size: "md",
        },
      },
      CheckboxGroup: {
        defaultProps: {
          size: "md",
        },
      },
      Radio: {
        defaultProps: {
          size: "md",
        },
      },
      RadioGroup: {
        defaultProps: {
          size: "md",
        },
      },
      Button: {
        defaultProps: {
          size: "md",
        },
      },
      InputWrapper: {
        defaultProps: {
          size: "md",
        },
      },
      InputLabel: {
        defaultProps: {
          size: "md",
          fw: "bold",
          style: {
            color: "#000",
            marginBottom: "0.5rem",
          },
        },
      },
      Switch: {
        defaultProps: {
          size: "md",
        },
      },
      ModalHeader: {
        defaultProps: {
          size: "md",
        },
      },
    },
    fontSizes: {
      xl: "1.333rem",
      lg: "1.16rem",
      md: "1rem",
      sm: "0.833rem",
      xs: "0.666rem",
    },
  };
  return (
    <>
      <MantineProvider theme={theme} withNormalizeCSS withGlobalStyles>
        <LoadingOverlay
          pos={"fixed"}
          visible={isLoading}
          overlayOpacity={0.6}
          zIndex={100000}
          w={"100vw"}
          h={"100vh"}
        />
        <Theme>
          <BrowserRouter basename="/portal-ipaam">
            <BaseLayout>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route
                  exact
                  path="/AcessoInterno"
                  element={<AcessoInterno />}
                />
                <Route exact path="/contato" element={<Contato />} />
                <Route exact path="/duvidas" element={<Duvidas />} />
                <Route exact path="/guias-rapidos" element={<GuiasRapidos />} />
                <Route exact path="/servicos" element={<Servicos />} />
                <Route
                  exact
                  path="/encontre-atividade"
                  element={<EncontreAtividade />}
                />
                <Route
                  exact
                  path="/encontre-atividade/:id"
                  element={<InformacoesAtividade />}
                />
                <Route
                  exact
                  path="/encontre-requerimento/:id"
                  element={<InformacoesRequerimentos />}
                />
              </Routes>
            </BaseLayout>
          </BrowserRouter>
        </Theme>
      </MantineProvider>
    </>
  );
}
