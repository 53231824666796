import { create } from "zustand";

export const useLoadingStore = create((set, get) => ({
  isLoading: false,
  numOfLoaders: 0,
  startLoading: () => {
    const { numOfLoaders } = get();
    if (numOfLoaders === 0) {
      set((state) => ({
        isLoading: true,
        numOfLoaders: state.numOfLoaders + 1,
      }));
    } else {
      set((state) => ({ numOfLoaders: state.numOfLoaders + 1 }));
    }
  },
  stopLoading: () => {
    const { numOfLoaders } = get();
    if (numOfLoaders === 0) return;
    if (numOfLoaders === 1) {
      set((state) => ({
        isLoading: false,
        numOfLoaders: state.numOfLoaders - 1,
      }));
    } else {
      set((state) => ({ numOfLoaders: state.numOfLoaders - 1 }));
    }
  },
}));
