import React from "react";
import FooterPrincipal from "../../components/footerPrincipal";
import HeaderPrincipal from "../../components/headerPrincipal";
import { DivPrincipal } from "./style";
import { TabsHeader } from "../../components/tabs";

const BaseLayout = ({ children }) => {
  return (
    <>
      <HeaderPrincipal />
      <DivPrincipal>
        <TabsHeader />
        {children}
      </DivPrincipal>
      <FooterPrincipal />
    </>
  );
};

export default BaseLayout;
