import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ChevronsUp from "../../assets/icons/ChevronsUpIcon";
import Whatsapp from "../../assets/icons/WhatsappIcon";
import { getSistemas } from "../../services/sistemas";
import { NumeroSislam } from "./components/numeroSislam";
import SobreSistemas from "./components/sobreSistemas";
import { ContanierFixo, Floating, IconContainer } from "./style";
import { SwiperComponent } from "./components/swiper";
import { CarouselComponent } from "./components/carousel";
import { Gerencias } from "./components/gerencias";
import { AcessoInformacao } from "./components/acessoInformacao";

const Home = () => {
  const [sistemas, setSistemas] = useState([]);

  const setDadosSistemas = async () => {
    const data = await getSistemas();
    setSistemas(data);
  };

  useEffect(() => {
    setDadosSistemas();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function openWhatsApp() {
    window.open("https://web.whatsapp.com/send?phone=+55(92)98429-4370");
  }

  return (
    <>
      <SwiperComponent />
      <CarouselComponent />
      <Gerencias />
      <AcessoInformacao />
      <NumeroSislam />
      <SobreSistemas sistemas={sistemas} />
      <ContanierFixo>
        <Floating onClick={() => openWhatsApp()}>
          <IconContainer>
            <Whatsapp />
          </IconContainer>
        </Floating>
        <Floating onClick={() => scrollToTop()}>
          <IconContainer>
            <ChevronsUp />
          </IconContainer>
        </Floating>
      </ContanierFixo>
    </>
  );
};

export default Home;
