import axios from "axios";
import { useLoadingStore } from "../stores/LoadingStore";

export const API_URL = process.env.REACT_APP_BASE_URL;

axios.defaults.baseURL = API_URL;

axios.interceptors.request.use((config) => {
  useLoadingStore.getState().startLoading();

  return config;
});

axios.interceptors.response.use(
  (response) => {
    useLoadingStore.getState().stopLoading();

    return response;
  },
  (error) => {
    useLoadingStore.getState().stopLoading();
    return Promise.reject(error);
  }
);

export default axios;
