import React, { useEffect, useState } from "react";
import { NUMERO, SISLAM } from "../../../../utils/Constants";
import { Titulo, TituloContainer } from "../../style";
import { Box, Card, Stack, Text } from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import api from "../../../../utils/api";

export const NumeroSislam = () => {
  const [numerosSislam, setNumerosSislam] = useState([]);

  const getNumerosSislam = async () => {
    try {
      const fetchData = await api.get("/api/dashboard/alertas");
      setNumerosSislam(fetchData.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getSvg = (index) => {
    const svgs = [
      `<svg width="42" height="52" viewBox="0 0 42 52" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31 6H36C37.3261 6 38.5979 6.52678 39.5355 7.46447C40.4732 8.40215 41 9.67392 41 11V46C41 47.3261 40.4732 48.5979 39.5355 49.5355C38.5979 50.4732 37.3261 51 36 51H6C4.67392 51 3.40215 50.4732 2.46447 49.5355C1.52678 48.5979 1 47.3261 1 46V11C1 9.67392 1.52678 8.40215 2.46447 7.46447C3.40215 6.52678 4.67392 6 6 6H11M13.5 31L18.5 36L28.5 26M13.5 1H28.5C29.8807 1 31 2.11929 31 3.5V8.5C31 9.88071 29.8807 11 28.5 11H13.5C12.1193 11 11 9.88071 11 8.5V3.5C11 2.11929 12.1193 1 13.5 1Z" stroke="#00C254" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
      `<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31.2501 25H20.8334M31.2501 16.6667H20.8334M39.5834 35.4167V10.4167C39.5834 9.3116 39.1444 8.25179 38.363 7.47039C37.5816 6.68899 36.5218 6.25 35.4168 6.25H8.33342M8.33342 6.25C9.43848 6.25 10.4983 6.68899 11.2797 7.47039C12.0611 8.25179 12.5001 9.3116 12.5001 10.4167V39.5833C12.5001 40.6884 12.9391 41.7482 13.7205 42.5296C14.5019 43.311 15.5617 43.75 16.6667 43.75M8.33342 6.25C7.22835 6.25 6.16854 6.68899 5.38714 7.47039C4.60573 8.25179 4.16675 9.3116 4.16675 10.4167V14.5833C4.16675 15.1359 4.38624 15.6658 4.77694 16.0565C5.16764 16.4472 5.69755 16.6667 6.25008 16.6667H12.5001M16.6667 43.75H41.6668C42.7718 43.75 43.8316 43.311 44.613 42.5296C45.3944 41.7482 45.8334 40.6884 45.8334 39.5833V37.5C45.8334 36.9475 45.6139 36.4176 45.2232 36.0269C44.8325 35.6362 44.3026 35.4167 43.7501 35.4167H22.9167C22.3642 35.4167 21.8343 35.6362 21.4436 36.0269C21.0529 36.4176 20.8334 36.9475 20.8334 37.5V39.5833C20.8334 40.6884 20.3944 41.7482 19.613 42.5296C18.8316 43.311 17.7718 43.75 16.6667 43.75Z" stroke="#00C254" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
      `<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.16675 43.7498C4.16675 37.4998 8.02091 32.5832 14.7501 31.2498C19.7917 30.2498 25.0001 27.0832 27.0834 24.9998M22.9167 41.6665C19.2585 41.6776 15.7298 40.3134 13.0303 37.8445C10.3309 35.3755 8.65793 31.9822 8.34328 28.3376C8.02863 24.6929 9.09527 21.0631 11.3316 18.168C13.568 15.273 16.8108 13.3242 20.4167 12.7082C32.2917 10.4165 35.4167 9.33317 39.5834 4.1665C41.6667 8.33317 43.7501 12.8748 43.7501 20.8332C43.7501 32.2915 33.7917 41.6665 22.9167 41.6665Z" stroke="#00C254" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
      `<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.75 24.9998L22.9167 29.1665L31.25 20.8331M41.6667 27.0831C41.6667 37.4998 34.375 42.7081 25.7084 45.729C25.2545 45.8828 24.7616 45.8754 24.3125 45.7081C15.625 42.7081 8.33337 37.4998 8.33337 27.0831V12.4998C8.33337 11.9473 8.55287 11.4174 8.94357 11.0267C9.33427 10.636 9.86417 10.4165 10.4167 10.4165C14.5834 10.4165 19.7917 7.91646 23.4167 4.7498C23.8581 4.37271 24.4195 4.16553 25 4.16553C25.5806 4.16553 26.142 4.37271 26.5834 4.7498C30.2292 7.9373 35.4167 10.4165 39.5834 10.4165C40.1359 10.4165 40.6658 10.636 41.0565 11.0267C41.4472 11.4174 41.6667 11.9473 41.6667 12.4998V27.0831Z" stroke="#00C254" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
      `<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.25 6.25V39.5833C6.25 40.6884 6.68899 41.7482 7.47039 42.5296C8.25179 43.311 9.3116 43.75 10.4167 43.75H43.75M14.5833 23.3479C14.5834 23.0722 14.6928 22.8077 14.8896 22.6112L27.5146 10.3612C27.7075 10.1664 27.9754 10.0562 28.2537 10.0562C28.5321 10.0562 28.7999 10.1664 28.9929 10.3612L38.6979 20.2362C38.8912 20.432 39.0008 20.697 39.0008 20.9729C39.0008 21.2489 38.8912 21.5139 38.6979 21.7096L26.0729 33.9596C25.8801 34.1544 25.6122 34.2646 25.3337 34.2646C25.0553 34.2646 24.7874 34.1544 24.5946 33.9596L14.8896 24.0846C14.6966 23.8898 14.5834 23.6175 14.5833 23.3479Z" stroke="#00C254" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
    ];
    return svgs[index] || "";
  };

  const getText = (index, titulo) => {
    if (index === 3) {
      return (
        <Stack gap={"0.3rem"} justify="flex-end">
          <Text
            fw="regular"
            fz="lg"
            style={{ color: "#2C3E50", wordBreak: "break-word" }}
          >
            Licenças emitidas
          </Text>
          <Text
            fw="regular"
            fz="sm"
            style={{ color: "#2C3E50", wordBreak: "break-word" }}
          >
            (Últimos 7 dias)
          </Text>
        </Stack>
      );
    } else if (index === 4) {
      return (
        <Stack gap={"0.3rem"}>
          <Text
            fw="regular"
            fz="lg"
            style={{ color: "#2C3E50", wordBreak: "break-word" }}
          >
            Licenças emitidas
          </Text>
          <Text
            fw="regular"
            fz="sm"
            style={{ color: "#2C3E50", wordBreak: "break-word" }}
          >
            (Últimos 30 dias)
          </Text>
        </Stack>
      );
    } else {
      return (
        <Text
          fw="regular"
          fz="lg"
          style={{ color: "#2C3E50", wordBreak: "break-word" }}
        >
          {titulo}
        </Text>
      );
    }
  };

  useEffect(() => {
    getNumerosSislam();
  }, []);

  return (
    <Box>
      <TituloContainer>
        <Titulo>{NUMERO}</Titulo>
        <Titulo negrito>{SISLAM}</Titulo>
      </TituloContainer>
      <Carousel
        slideSize={{ base: "100%", sm: "50%", md: "25%" }}
        slideGap={{ base: 50, sm: "md" }}
        align="start"
        withControls
        loop
        withIndicators
        styles={{
          control: { color: "#00C254" },
        }}
      >
        {numerosSislam?.map((numero, index) => (
          <Carousel.Slide key={index}>
            <Card
              shadow="lg"
              padding="lg"
              radius="lg"
              withBorder
              style={{
                position: "relative",
                height: "140px",
                width: "350px",
                display: "flex",
                background: "#F6F9F6",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  left: "16px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
                dangerouslySetInnerHTML={{ __html: getSvg(index) }}
              />
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  height: "100%",
                }}
              >
                <Text fw="bold" fz="h1" style={{ color: "#00C254" }}>
                  {numero.numero}
                </Text>
                {getText(index, numero.titulo)}
              </Box>
            </Card>
          </Carousel.Slide>
        ))}
      </Carousel>
    </Box>
  );
};
