import { Accordion, Group, List, Stack, Text } from "@mantine/core";
import { YouTubeEmbed } from "../youtubeEmbed";

export const AccordionEmpreendimento = () => {
  return (
    <Accordion.Item value={"empreendimento"}>
      <Accordion.Control>
        <Text fw={"bold"} c={"#333"}>
          Etapa 2: Empreendimento
        </Text>
      </Accordion.Control>
      <Accordion.Panel>
        <Group justify="space-between" align="flex-start">
          <Stack gap={"1rem"} w={"55%"}>
            <Text>
              Para preencher os dados do empreendimento no protocolo, você pode
              escolher entre as opções{" "}
              <Text span fw={700}>
                Vincular Empreendimento
              </Text>{" "}
              (selecionar um cadastro existente) ou{" "}
              <Text span fw={700}>
                Cadastrar Novo Empreendimento
              </Text>{" "}
              (registrar um empreendimento inédito).
            </Text>
            <List type="ordered" spacing={"1.5rem"}>
              <List.Item>
                <Text span fw={700}>
                  Vincular Empreendimento
                </Text>
                <List spacing={"0.5rem"}>
                  <List.Item>
                    Utilize essa opção para selecionar um empreendimento já
                    cadastrado no sistema e vinculado ao CPF/CNPJ do
                    empreendedor.
                  </List.Item>
                  <List.Item>
                    Na listagem de empreendimentos, localize a última coluna{" "}
                    <Text span fw={700}>
                      (Ações)
                    </Text>{" "}
                    e clique no ícone de{" "}
                    <Text span fw={700}>
                      três barras
                    </Text>{" "}
                    para acessar as opções:
                    <List>
                      <List.Item>
                        <Text span fw={700}>
                          Vincular Empreendimento:
                        </Text>
                        <List>
                          <List.Item>
                            Confirme informações básicas, como{" "}
                            <Text span fw={700}>
                              endereço
                            </Text>{" "}
                            e{" "}
                            <Text span fw={700}>
                              localização
                            </Text>{" "}
                            (urbano, rural ou leito de rio).
                          </List.Item>
                          <List.Item>
                            Clique em{" "}
                            <Text span fw={700}>
                              "Validar Dados"
                            </Text>{" "}
                            para concluir a vinculação do empreendimento ao
                            protocolo.
                          </List.Item>
                        </List>
                      </List.Item>
                      <List.Item>
                        <Text span fw={700}>
                          Visualizar Empreendimento:
                        </Text>{" "}
                        Consulte todos os detalhes cadastrados, que serão
                        automaticamente carregados no protocolo.
                      </List.Item>
                    </List>
                  </List.Item>
                  <Text fw={700} mt={"0.5rem"}>
                    Nota: Apenas empreendimentos relacionados ao CPF/CNPJ do
                    empreendedor podem ser vinculados.
                  </Text>
                </List>
              </List.Item>
              <List.Item>
                <Text fw={700}>Cadastrar Novo Empreendimento</Text>
                Para registrar um novo empreendimento, siga os passos abaixo:
                <List type="ordered" spacing={"0.5rem"} mt={"0.7rem"}>
                  <List.Item>
                    Clique no botão no canto superior direito:{" "}
                    <Text span fw={700}>
                      "Cadastrar Empreendimento".
                    </Text>
                  </List.Item>
                  <List.Item>
                    Preencha os campos obrigatórios nos seguintes cartões:
                    <List>
                      <List.Item>
                        <Text fw={700}>Dados do Empreendimento:</Text>
                        <List>
                          <List.Item>
                            Informações obrigatórias: Denominação, Jurisdição,
                            UF, Município e Localização (urbano, leito de rio ou
                            rural - funcionalidade para rural ainda não
                            disponível).
                          </List.Item>
                        </List>
                      </List.Item>
                      <List.Item>
                        <Text fw={700}>Endereço do Empreendimento:</Text>
                        <List>
                          <List.Item>
                            <Text span fw={700}>
                              Empreendimentos Urbanos:
                            </Text>{" "}
                            Preencha CEP (somente CEPs de Manaus são aceitos),
                            Logradouro, Número, Complemento, Bairro, UF e
                            Município.
                          </List.Item>
                          <List.Item>
                            {" "}
                            <Text span fw={700}>
                              Empreendimentos em Leito de Rio:
                            </Text>{" "}
                            Informe UF, Município de Acesso e Roteiro de Acesso.
                          </List.Item>
                        </List>
                      </List.Item>
                      <List.Item>
                        <Text span fw={700}>
                          Endereço de Correspondência:
                        </Text>{" "}
                        Caso seja diferente do endereço do empreendimento,
                        insira: CEP, Logradouro, Número, Complemento, Bairro, UF
                        e Município.
                        <List>
                          <List.Item>
                            Dica: Utilize a opção de copiar o endereço do
                            empreendimento para agilizar o preenchimento, caso
                            os endereços sejam iguais.
                          </List.Item>
                        </List>
                      </List.Item>
                    </List>
                  </List.Item>
                  <List.Item>
                    Clique em{" "}
                    <Text span fw={700}>
                      "Avançar"
                    </Text>{" "}
                    para salvar as informações e seguir para a próxima etapa.
                  </List.Item>
                </List>
              </List.Item>
            </List>
            <Stack gap={"0.5rem"}>
              <Text>Notas Importantes:</Text>
              <List withPadding>
                <List.Item>
                  O sistema valida automaticamente as informações e as vincula
                  ao CPF/CNPJ do empreendedor cadastrado no protocolo.
                </List.Item>
                <List.Item>
                  Alterações em informações previamente registradas só podem ser
                  feitas no sistema{" "}
                  <Text span fw={700}>
                    Entrada Única
                  </Text>
                  , não diretamente no SISLAM.
                </List.Item>
                <List.Item>
                  Certifique-se de que todas as informações estão corretas antes
                  de prosseguir.
                </List.Item>
              </List>
            </Stack>
          </Stack>
          <YouTubeEmbed
            title="Empreendimento"
            link="https://www.youtube.com/watch?v=CR0xEjhOfYo&list=PLN8ZE762l-EC5wX0y3hB0IZ0NYNGTV3gN&index=5"
          />
        </Group>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
