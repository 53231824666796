import { Accordion, Group, List, Stack, Text } from "@mantine/core";
import { YouTubeEmbed } from "../youtubeEmbed";

export const AccordionEmpreendedor = () => {
  return (
    <Accordion.Item value={"empreendedor"}>
      <Accordion.Control>
        <Text fw={"bold"} c={"#333"}>
          Etapa 1: Empreendedor
        </Text>
      </Accordion.Control>
      <Accordion.Panel>
        <Group justify="space-between" align="flex-start">
          <Stack gap={"1rem"} w={"55%"}>
            <Text>
              Para preencher os dados do empreendedor, você pode escolher entre
              duas opções:{" "}
              <Text span fw={700}>
                Vincular Empreendedor
              </Text>{" "}
              ou{" "}
              <Text span fw={700}>
                Cadastrar Novo Empreendedor.
              </Text>
            </Text>
            <List type="ordered" spacing={"1.5rem"}>
              <List.Item>
                <Text span fw={700}>
                  Vincular Empreendedor
                </Text>
                <List spacing={"0.5rem"}>
                  <List.Item>
                    Essa opção permite selecionar um empreendedor já cadastrado
                    no sistema{" "}
                    <Text span fw={700}>
                      Entrada Única.
                    </Text>
                  </List.Item>
                  <List.Item>
                    Na listagem de empreendedores, vá até a última coluna
                    (Ações) e clique no ícone de{" "}
                    <Text span fw={700}>
                      três barras
                    </Text>{" "}
                    para acessar as opções disponíveis:
                    <List>
                      <List.Item>
                        <Text span fw={700}>
                          Vincular Empreendedor
                        </Text>
                        <List>
                          <List.Item>
                            Confirme informações de segurança, como{" "}
                            <Text span fw={700}>
                              nome da mãe
                            </Text>{" "}
                            e{" "}
                            <Text span fw={700}>
                              data de nascimento
                            </Text>{" "}
                            (para pessoas físicas) ou razão social e data de
                            constituição (para pessoas jurídicas).
                          </List.Item>
                          <List.Item>
                            Clique em{" "}
                            <Text span fw={700}>
                              "Validar Dados"
                            </Text>{" "}
                            para concluir a vinculação.
                          </List.Item>
                        </List>
                      </List.Item>
                      <List.Item>
                        <Text span fw={700}>
                          Visualizar Empreendedor:
                        </Text>{" "}
                        Consulte todos os dados do empreendedor, que são
                        carregados automaticamente e não podem ser alterados no
                        SISLAM.
                      </List.Item>
                    </List>
                  </List.Item>
                </List>
              </List.Item>
              <List.Item>
                <Text fw={700}>Cadastrar Novo Empreendedor</Text>
                <List spacing={"0.3rem"}>
                  <List.Item>
                    Siga os passos abaixo para cadastrar um empreendedor
                    inédito:
                    <List type="ordered" mt={"0.5rem"}>
                      <List.Item>
                        Clique no botão no canto superior direito:{" "}
                        <Text span fw={700}>
                          "Cadastrar Empreendedor".
                        </Text>
                      </List.Item>
                      <List.Item>
                        Digite o{" "}
                        <Text span fw={700}>
                          CPF
                        </Text>{" "}
                        ou{" "}
                        <Text span fw={700}>
                          CNPJ
                        </Text>{" "}
                        no campo indicado e clique no ícone de{" "}
                        <Text span fw={700}>
                          lupa
                        </Text>{" "}
                        para buscar.
                      </List.Item>
                      <List.Item>
                        Confirme os dados básicos exigidos por segurança:
                        <List>
                          <List.Item>
                            <Text span fw={700}>
                              Pessoas Físicas:
                            </Text>
                            Nome completo, data de nascimento e outros dados
                            básicos.
                          </List.Item>
                          <List.Item>
                            <Text span fw={700}>
                              Pessoas Jurídicas:
                            </Text>{" "}
                            Razão social e data de constituição.
                          </List.Item>
                        </List>
                      </List.Item>
                      <List.Item>
                        O sistema carregará automaticamente informações básicas
                        previamente registradas no{" "}
                        <Text span fw={700}>
                          Entrada Única.
                        </Text>
                      </List.Item>
                      <List.Item>
                        Clique em{" "}
                        <Text span fw={700}>
                          "Avançar"
                        </Text>{" "}
                        para seguir para a próxima etapa.
                      </List.Item>
                    </List>
                  </List.Item>
                </List>
              </List.Item>
            </List>
            <Stack gap={"0.5rem"}>
              <Text>Nota Importante:</Text>
              <List withPadding>
                <List.Item>
                  Essa página não permite a alteração de informações do
                  empreendedor. Alterações devem ser feitas diretamente no
                  sistema{" "}
                  <Text span fw={700}>
                    Entrada Única.
                  </Text>
                </List.Item>
                <List.Item>
                  Certifique-se de que o e-mail do empreendedor está
                  corretamente registrado no{" "}
                  <Text span fw={700}>
                    Entrada Única
                  </Text>
                  , pois ele é obrigatório para o cadastro.
                </List.Item>
              </List>
            </Stack>
          </Stack>
          <YouTubeEmbed
            title="Empreendedor"
            link="https://www.youtube.com/watch?v=aSSKCg63dqA&list=PLN8ZE762l-EC5wX0y3hB0IZ0NYNGTV3gN&index=4&ab_channel=ImprensaIpaam"
          />
        </Group>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
