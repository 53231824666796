import { Accordion, Group, List, Stack, Text } from "@mantine/core";
import { YouTubeEmbed } from "../youtubeEmbed";

export const AccordionEnquadramento = () => {
  return (
    <Accordion.Item value={"enquadramento"}>
      <Accordion.Control>
        <Text fw={"bold"} c={"#333"}>
          Etapa 9: Enquadramento
        </Text>
      </Accordion.Control>
      <Accordion.Panel>
        <Group justify="space-between" align="flex-start">
          <Stack gap={"1rem"} w={"55%"}>
            <List>
              <List.Item>
                É onde o usuário visualiza o resumo da solicitação.
              </List.Item>
              <List.Item>
                Taxas a serem pagas, informações inseridas e pontos de atenção.
              </List.Item>
              <List.Item>
                Clique em{" "}
                <Text span fw={700}>
                  "finalizar"
                </Text>{" "}
                e depois em
                <Text span fw={700}>
                  "confirmar solicitação"
                </Text>{" "}
                para enviar.
              </List.Item>
              <List.Item>
                A mensagem{" "}
                <Text span fw={700}>
                  "solicitação finalizada com sucesso"
                </Text>{" "}
                será exibida na tela.
              </List.Item>
              <List.Item>
                Para dar sequência à solicitação, as taxas deverão ser pagas
                para que o protocolo seja enviado para o modo de análise. Para
                isso:
              </List.Item>
              <List.Item>
                Selecione a opção{" "}
                <Text span fw={700}>
                  Baixar DAE
                </Text>
                .
                <List>
                  <List.Item>
                    Número de processo/protocolo é gerado somente após ser pago.
                  </List.Item>
                </List>
              </List.Item>
              <List.Item>
                O protocolo será exibido na listagem de protocolos inicial.
              </List.Item>
            </List>
          </Stack>
          <YouTubeEmbed
            title="Enquadramento"
            link="https://www.youtube.com/watch?v=DPhOkQL7H80&list=PLN8ZE762l-EC5wX0y3hB0IZ0NYNGTV3gN&index=12&ab_channel=ImprensaIpaam"
          />
        </Group>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
