import api from "../utils/api";

export const getDuvidas = async () => {
  try {
    const fetchData = await api.get("/api/duvidas/findAll");
    return fetchData.data;
  } catch (error) {
    console.error(error);
  }
};
