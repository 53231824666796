import api from "../utils/api";

export const getSistemas = async () => {
  try {
    const fetchData = await api.get("/api/sistema/listar");
    return fetchData.data;
  } catch (error) {
    console.error(error);
  }
};
