import { Carousel } from "@mantine/carousel";
import { Box, Button, Stack, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import Autoplay from "embla-carousel-autoplay";

export const SwiperComponent = () => {
  const navigate = useNavigate();
  const autoplay = useRef(Autoplay({ delay: 5000 }));

  const basePath = `${process.env.PUBLIC_URL}/images/`;

  const renderSlideContent = () => (
    <Stack pos="absolute" top="21%" left="5%" gap="1rem" w="30%">
      <Text
        c="#ffffff"
        fz={"2rem"}
        style={{
          zIndex: "2",
          fontWeight: "bold",
        }}
      >
        Portal de Sistemas
      </Text>
      <Text
        c="#ffffff"
        fz={"1.10rem"}
        style={{
          zIndex: "2",
        }}
      >
        Seu portal ambiental para acesso às informações sobre os sistemas em uso
        no IPAAM. Encontre os requisitos e termos de referência para
        regularização de sua atividade através do link a seguir.
      </Text>
      <Button
        style={{
          zIndex: "2",
          fontWeight: "initial",
          width: "100%",
        }}
        fz={"1.10rem"}
        radius="xl"
        color="green"
        onClick={() => navigate("/encontre-atividade")}
      >
        Pesquisa de requisitos por atividade
      </Button>
      <Button
        style={{
          zIndex: "2",
          fontWeight: "initial",
          width: "100%",
        }}
        fz={"1.10rem"}
        radius="xl"
        color="green"
        onClick={() => navigate("/duvidas")}
      >
        Perguntas frequentes
      </Button>
    </Stack>
  );

  return (
    <Box pos="relative" mt={"-4rem"} h={"75vh"}>
      {renderSlideContent()}
      <Carousel withIndicators h={"100%"} loop plugins={[autoplay.current]}>
        {[
          `${basePath}carrossel1.jpg`,
          `${basePath}carrossel2.png`,
          `${basePath}carrossel3.png`,
        ].map((src, index) => {
          const objectPositions = ["center 65%", "center 0%", "center 90%"];

          return (
            <Carousel.Slide key={index}>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "75vh",
                  borderRadius: "15px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={src}
                  alt={`Imagem ilustrativa ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: objectPositions[index],
                    borderRadius: "15px",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundImage:
                      "linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0) 50%)",
                  }}
                />
              </div>
            </Carousel.Slide>
          );
        })}
      </Carousel>
    </Box>
  );
};
