import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, Button, Card, Image, Stack, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import api from "../../utils/api";

import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { CardInformacoesAtividade } from "./cardInformacoes";
import { InformacoesFases } from "./informacoesFases";

export const InformacoesAtividade = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [atividade, setAtividade] = useState();
  const [fasesSelecionadas, setFasesSelecionadas] = useState("");
  const [tipoPessoaFiltro, setTipoPessoaFiltro] = useState(
    "pessoa física e jurídica"
  );
  const [requisitos, setRequisitos] = useState([]);

  const form = useForm({
    initialValues: {
      fases: [],
      tipoPessoa: [],
    },
  });

  const handleExportPDF = () => {
    const codigo =
      atividade?.codigo !== "N/A" &&
      atividade?.codigo !== "0000" &&
      atividade?.codigo + " - ";

    const req = requisitos.map((r) => ({
      titulo: r.tituloLista,
      itens: r.listaRequisitos.map((item, index) => ({
        index: String(index + 1),
        descricao: item.nomeRequisito,
        obrigatorio: item?.obrigatorio ? "Obrigatório" : "Opcional",
      })),
    }));

    const dto = {
      atividadeRequerimentoText: atividade?.nome,
      tipoPessoa: tipoPessoaFiltro,
      fases: fasesSelecionadas,
      titulo: `Requisitos para a atividade ${codigo}${atividade.nome}`,
      tipologia: atividade?.tipologia,
      gerenciaCompetente: atividade?.gerencia,
      localizacao: atividade?.localizacao,
      ppd: atividade?.ppd,
      cnaes: atividade?.cnaes,
      requisitos: req,
      primeiroPasso: `No SISLAM, na etapa requerimento selecione o Tipo de Requerimento igual a ${
        atividade?.codigo === "0000"
          ? "Declaração de inexigibilidade"
          : atividade?.tipoRequerimento
      }`,
      segundoPasso:
        "No campo Requerimento, selecione a Fase do licenciamento desejada",
      terceiroPasso: `Selecione a Tipologia ${atividade?.tipologia}. Depois, selecione a Atividade desejada inserindo o Código da Atividade.`,
    };

    api
      .post(`/api/pdf/gerar/atividadeRequerimento`, dto, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        openByteArrayNewTab(response.data);
      });
  };

  const openByteArrayNewTab = (byteArray) => {
    const type = "application/pdf";
    const blob = new Blob([byteArray], { type });
    const url = URL.createObjectURL(blob);

    const newTab = window.open(url, "_blank");
    if (newTab) newTab.focus();
  };

  const getAtividade = async () => {
    try {
      const response = await api.get(
        `/api/configurador/atividade/findById/${id}`
      );
      setAtividade(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getAtividade();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (atividade) {
      setFasesSelecionadas(atividade?.fasesString);
    }
  }, [atividade]);

  return (
    <Stack gap={"1.5rem"}>
      <Button
        w={"10%"}
        size="lg"
        variant="subtle"
        leftSection={<FontAwesomeIcon icon={faArrowLeft} />}
        onClick={() => navigate("/encontre-atividade")}
      >
        Voltar
      </Button>
      <Card
        id="exportable-box"
        shadow="xs"
        padding={"lg"}
        radius="xs"
        style={{
          backgroundColor: "#F7F9F6",
        }}
        mt={"-1rem"}
      >
        <Stack gap={"1.5rem"}>
          <CardInformacoesAtividade
            atividade={atividade}
            fasesSelecionadas={fasesSelecionadas}
            handleExportPDF={handleExportPDF}
          />
          <InformacoesFases
            form={form}
            atividade={atividade}
            setFasesSelecionadas={setFasesSelecionadas}
            todasFasesString={atividade?.fasesString}
            setTipoPessoaFiltro={setTipoPessoaFiltro}
            requisitos={requisitos}
            setRequisitos={setRequisitos}
          />
          <Accordion
            defaultValue={"passoLicenca"}
            variant="contained"
            styles={{
              item: {
                backgroundColor: "#fff",
                "&[data-active]": {
                  backgroundColor: "#fff",
                },
              },
              chevron: {
                "&[data-rotate]": {
                  transform: "rotate(-90deg)",
                },
              },
            }}
          >
            <Accordion.Item value={"passoLicenca"}>
              <Accordion.Control>
                <Text fw={"bold"} c={"#666"}>
                  Passo a passo para realizar a seleção do requerimento em
                  SISLAM
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                <Stack gap={"1.5rem"}>
                  <Stack gap={"0.5rem"}>
                    <Text>
                      <Text span fw={"bold"}>
                        1º passo:{" "}
                      </Text>
                      No SISLAM, na etapa requerimento selecione o Tipo de
                      Requerimento igual a{" "}
                      <Text span fw={"bold"}>
                        {atividade?.codigo === "0000"
                          ? "Declaração de inexigibilidade"
                          : atividade?.tipoRequerimento}
                      </Text>
                    </Text>
                    <Text>
                      <Text span fw={"bold"}>
                        2º passo:{" "}
                      </Text>
                      No campo{" "}
                      <Text span fw={"bold"}>
                        Requerimento,
                      </Text>{" "}
                      selecione a Fase do licenciamento desejada
                    </Text>
                    <Text>
                      <Text span fw={"bold"}>
                        3º passo:{" "}
                      </Text>
                      Selecione a Tipologia{" "}
                      <Text span fw={"bold"}>
                        {atividade?.tipologia}.
                      </Text>{" "}
                      Depois, selecione a Atividade desejada inserindo o{" "}
                      <Text span fw={"bold"}>
                        Código da Atividade
                      </Text>
                    </Text>
                  </Stack>
                  <Image
                    src={`${process.env.PUBLIC_URL}/images/passos.png`}
                    alt="passosLicenciamento"
                    w={"50%"}
                  />
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Stack>
      </Card>
    </Stack>
  );
};
